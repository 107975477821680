import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Card, Table, Tag, Button, Typography } from 'antd';
import { FaTrophy, FaBook } from 'react-icons/fa';
import styled from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';
import Chat from './Chat';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import apiUrl from '../config';

const { Meta } = Card;
const { Title, Text } = Typography;

const AssessmentContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

const AssessmentContent = styled.div`
  flex: 6;
  padding-right: 20px;
`;

const BotContainer = styled.div`
  flex: 4;
  margin-left: 20px;
`;

const CardStyled = styled(Card)`
  margin-top: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;


function AssessmentDetail() {
  const { id } = useParams();
  const [assessmentData, setAssessmentData] = useState({});
  const [userFeedback, setUserFeedback] = useState([]);

  useEffect(() => {
    const fetchAssessmentDetails = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/get-assessment/${id}`);
        if (response.ok) {
          const data = await response.json();
          setAssessmentData(data);
        } else {
          console.error('Errore nel recupero dei dettagli dell\'assessment.');
        }
      } catch (error) {
        console.error('Errore durante la chiamata al backend:', error);
      }
    };

    const loadUserFeedback = () => {
      const savedUserFeedback = JSON.parse(localStorage.getItem(`userFeedback_${id}`));
      if (savedUserFeedback) {
        setUserFeedback(savedUserFeedback);
      } else {
        const dummyRatings = [5, 3, 1];
        const dummyFeedbacks = ['Eccellente', 'Buono', 'Scarso'];

        const generatedUserFeedback = Array.from({ length: 10 }, (_, index) => ({
          name: `User ${index + 1}`,
          date: '31/08/2024',
          initialPerformance: Math.floor(Math.random() * 50) + 50,
          finalPerformance: Math.floor(Math.random() * 50) + 50,
          rating: dummyRatings[index % dummyRatings.length],
          feedback: dummyFeedbacks[index % dummyFeedbacks.length],
        })).map(user => ({
          ...user,
          improvement: user.finalPerformance - user.initialPerformance
        }));

        localStorage.setItem(`userFeedback_${id}`, JSON.stringify(generatedUserFeedback));
        setUserFeedback(generatedUserFeedback);
      }
    };

    fetchAssessmentDetails();
    loadUserFeedback();
  }, [id]);

  const totalParticipants = userFeedback.length;
  const averageRating = Math.round(
    (userFeedback.reduce((sum, user) => sum + user.rating, 0) / 
     totalParticipants) * 20
  );

  const columns = [
    {
      title: 'Nome Utente',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span>
          <Link to={`/user/${record.key}`} style={{ marginLeft: '10px', textDecoration: 'none', color: '#333' }}>
            {text}
          </Link>
        </span>
      ),
    },
    {
      title: 'Data di Completamento',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Performance Iniziale',
      dataIndex: 'initialPerformance',
      key: 'initialPerformance',
    },
    {
      title: 'Performance Finale',
      dataIndex: 'finalPerformance',
      key: 'finalPerformance',
    },
    {
      title: 'Miglioramento',
      dataIndex: 'improvement',
      key: 'improvement',
      render: improvement => (
        <Tag color={improvement >= 0 ? 'green' : 'volcano'}>
          {improvement >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />} {improvement}%
        </Tag>
      ),
    },
    {
      title: 'Valutazione',
      dataIndex: 'rating',
      key: 'rating',
      render: rating => (
        <div style={{ width: 50, height: 50 }}>
          <CircularProgressbar
            value={(rating / 5) * 100}
            text={`${Math.round((rating / 5) * 100)}%`}
            styles={buildStyles({
              textSize: '28px',
              pathColor: `rgba(62, 152, 199, ${(rating / 5)})`,
              textColor: '#4caf50',
              trailColor: '#d6d6d6',
            })}
          />
        </div>
      ),
    },
    {
      title: 'Feedback',
      dataIndex: 'feedback',
      key: 'feedback',
    },
  ];

  const dataSource = userFeedback.map((user, index) => ({
    key: index + 1,
    name: user.name,
    date: user.date,
    initialPerformance: `${user.initialPerformance}%`,
    finalPerformance: `${user.finalPerformance}%`,
    improvement: user.improvement,
    rating: user.rating,
    feedback: user.feedback,
  }));

  return (
    <AssessmentContainer>
      <AssessmentContent>
        <Title level={2}>{assessmentData.courseName}</Title>
        <Text>{assessmentData.objectives && assessmentData.objectives.join(', ')}</Text>

        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col span={6}>
            <CardStyled>
              <FaTrophy size={30} color="#6c63ff" />
              <Meta title={`${averageRating}%`} description="Valutazione Media" />
            </CardStyled>
          </Col>
          <Col span={6}>
            <CardStyled>
              <FaBook size={30} color="#ff6584" />
              <Meta title={totalParticipants} description="Partecipanti" />
            </CardStyled>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: true, y: 300 }}  // Aggiungi scroll orizzontale per schermi piccoli
          style={{ marginTop: 20 }}
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        />

        <Button type="primary" style={{ marginTop: 20 }}>Scarica Report</Button>
      </AssessmentContent>

      <BotContainer>
        <Chat assessmentId={id} noIcons />
      </BotContainer>
    </AssessmentContainer>
  );
}

export default AssessmentDetail;
