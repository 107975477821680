// components/PerformanceList.js
import React from 'react';
import { List, Typography, Avatar, Progress } from 'antd'; // Importa i componenti di Ant Design
import styled from 'styled-components';
import { FaMedal } from 'react-icons/fa';

// Container per l'intera sezione delle performance, con bordo arrotondato e ombra
const PerformanceContainer = styled.div`
  margin: 20px 0;
  background-color: #f2f2f7; /* Colore di sfondo chiaro */
  padding: 20px;
  border-radius: 12px; /* Bordo stondato */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Ombra leggera */
`;

// Stile per ogni elemento della lista, con allineamento al centro
const StyledListItem = styled(List.Item)`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

// Stile per l'avatar contenente l'icona
const StyledAvatar = styled(Avatar)`
  background-color: ${props => (props.good ? '#ffcc00' : '#ff3b30')}; /* Oro per best performance, rosso per worst */
  margin-right: 10px;
`;

const ChartContainer = styled.div`
  width: 50px;
  height: 50px;
  margin-left: auto; /* Spinge il grafico circolare all'estrema destra */
`;

function PerformanceList({ title, data, good }) {
  return (
    <PerformanceContainer>
      <Typography.Title level={4}>{title}</Typography.Title> {/* Usa Typography di Ant Design */}
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <StyledListItem>
            <StyledAvatar good={good}>
              <FaMedal color="white" />
            </StyledAvatar>
            <List.Item.Meta
              title={item.course}
              description={`Partecipanti: ${item.participants}`}
            />
            <ChartContainer>
              <Progress
                type="circle"
                percent={item.rating}
                format={percent => `${percent}%`}
                width={50}
                strokeColor={good ? '#34c759' : '#ff3b30'} // Verde per good, rosso per worst
              />
            </ChartContainer>
          </StyledListItem>
        )}
      />
    </PerformanceContainer>
  );
}

export default PerformanceList;
