import React, { createContext, useState } from 'react';

// Creiamo il context per gli achievement
export const AchievementContext = createContext();

export const AchievementProvider = ({ children }) => {
  const [unlockedAchievements, setUnlockedAchievements] = useState([]);

  return (
    <AchievementContext.Provider value={{ unlockedAchievements, setUnlockedAchievements }}>
      {children}
    </AchievementContext.Provider>
  );
};