// components/UtilizationChart.js
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'G', hours: 12 },
  { name: 'F', hours: 15 },
  { name: 'M', hours: 18 },
  { name: 'A', hours: 21 },
  { name: 'M', hours: 19 },
  { name: 'G', hours: 23 },
  { name: 'L', hours: 25 },
  { name: 'S', hours: 27 },
  { name: 'O', hours: 30 },
];

function UtilizationChart() {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="hours" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default UtilizationChart;
