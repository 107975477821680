export const templates = {
    'Nozioni di Base sulla Cybersecurity': {
      courseName: 'Nozioni di Base sulla Cybersecurity',
      objectives: 'Comprendere i principi di base della cybersecurity\nIdentificare le minacce comuni',
      instructions: [
        { id: Date.now(), title: 'Introduzione alla Cybersecurity', content: 'Panoramica sui concetti di base e sulle minacce.' },
        { id: Date.now() + 1, title: 'Best Practices', content: 'Passi per garantire la sicurezza informatica di base.' },
      ],
      autoClose: false,
      questionsCount: 10,
    },
    'Sicurezza sul Lavoro': {
      courseName: 'Sicurezza sul Lavoro',
      objectives: 'Valutare le competenze dei discenti postcorso sicurezza per dirigenti\nPrevenire incidenti sul lavoro\nVerificare idoneità dello studente nel ricoprire il ruolo di RSPP',
      instructions: [
        { id: Date.now(), title: 'Moduli del corso', content: 'MODULO 01 – Giuridico Normativo 1. Sistema legislativo in materia di sicurezza. a. DLgs 475/92 – DPI b. DLgs 151/2001 – rischi in gravidanza e allattamento c. DPR 462/2001 - denuncia di installazioni e dispositivi di protezione contro le scariche atmosferiche, di dispositivi di messa a terra di impianti elettrici d. DM 388/2003 – primo soccorso aziendale e. DLgs 152/2006 – ambiente f. D. 37/2008 – installazione impianti g. D. 11/04/2011 – verifiche periodiche attrezzature h. DPR 177/2011 – ambienti confinati o sospetto inquinamento i. Regolamento UE 2023/1230 – macchine j. DPR 151/2011 – autorizzazioni prevenzione incendi k. D. 03/08/2015 – norme tecniche di prevenzione incendi l. DM 01/09/2021 – manutenzione antincendio m. DM 02/09/2021 – gestione emergenze e antincendio n. DM 03/09/2021 – valutazione rischio incendio 2. Gli organi di vigilanza e le procedure ispettive. a. DLgs 758/1994 3. Soggetti del sistema di prevenzione aziendale secondo il DLgs 81/08: compiti, obblighi, responsabilità e tutela assicurativa. a. Dlgs 81/2008, articoli 17, 18, 19, 20, 25, 33, 47, 50 4. Delega di funzioni. a. Dlgs 81/2008, articolo 16 5. La responsabilità civile e penale e la tutela assicurativa. 6. La responsabilità amministrativa delle persone giuridiche, delle società e delle assicurazioni, anche prive di responsabilità giuridica (ex DLgs 231/2001 e smi. a. DLgs 231/2001, b. DLgs 81/2008, articolo 30 7. I sistemi di qualificazione delle imprese e la patente a punti in edilizia. a. DLgs 81/2008 articolo 26 b. Per la patente a punti non posso aiutarti' },
        { id: Date.now() + 1, title: 'Focus', content: 'Verificare competenze specifiche per il luogo di lavoro ovvero un canitere con lavori ad alta quota' },
      ],
      autoClose: true,
      questionsCount: 5,
    },
    'Formazione sui Software': {
      courseName: 'Formazione sui Software',
      objectives: 'Imparare a utilizzare strumenti software essenziali\nMigliorare la produttività con le best practices software',
      instructions: [
        { id: Date.now(), title: 'Introduzione ai Software', content: 'Introduzione agli strumenti software utilizzati in azienda.' },
        { id: Date.now() + 1, title: 'Funzionalità Avanzate', content: 'Esplorare funzionalità avanzate e scorciatoie.' },
      ],
      autoClose: false,
      questionsCount: 8,
    },
};

export default templates;
