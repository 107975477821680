import React, { useState, useEffect, useRef, useContext } from 'react';
import { Input, Button, Layout, Typography, Row, Col, Card, Avatar, Divider, notification } from 'antd';
import { useParams, useLocation } from 'react-router-dom';
import { UserOutlined, RobotOutlined, TrophyOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import './Chat.css';
import KpiProgress from './KpiProgress';
import apiUrl from '../config';
import { AchievementContext } from './AchievementContext';

const { Header, Content } = Layout;
const { Title } = Typography;

// Chat container styling
const ChatContainer = styled.div`
  background: #ffffff;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

// Chatbox styling with auto-scroll
const ChatBox = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 60vh;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

// Message styling
const ChatItem = styled.div`
  display: flex;
  flex-direction: ${props => (props.role === 'user' ? 'row-reverse' : 'row')};
  align-items: flex-start;
  margin-bottom: 10px;
  gap: 10px;
`;

// Chat bubble styling
const ChatBubble = styled.div`
  background-color: ${props => (props.role === 'user' ? '#ffffff' : '#ffffff')};
  color: ${props => (props.role === 'user' ? 'black' : 'black')};
  padding: 12px 18px;
  border-radius: 16px;
  max-width: 90%;
  font-size: 15px;
  line-height: 1.5;
  word-wrap: break-word;
`;

// Input area for sending messages
const StyledInput = styled(Input.TextArea)`
  border-radius: 20px;
  border: 1px solid #d1d1d6;
  padding: 15px;
  font-size: 16px;
  &:focus {
    border-color: #007aff;
    box-shadow: 0 0 5px rgba(0, 122, 255, 0.5);
  }
`;

// Styled send button
const StyledButton = styled(Button)`
  border-radius: 25px;
  background-color: #007aff;
  color: white;
  border: none;
  font-size: 16px;
  height: 45px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f0faff;
  }
`;

// Save assessment button
const SaveButton = styled(Button)`
  border-radius: 20px;
  font-size: 16px;
  margin-top: 20px;
  height: 45px;
  width: 150px;
  background-color: white;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #218838;
  }
`;

function Chat() {
  const { id } = useParams();
  const location = useLocation();
  
  const { unlockedAchievements, setUnlockedAchievements } = useContext(AchievementContext);
  const [achievementQueue, setAchievementQueue] = useState([]);
  const [messages, setMessages] = useState(location.state?.conversation || []);
  const [userInput, setUserInput] = useState('');
  const [kpis, setKpis] = useState([]);
  const chatEndRef = useRef(null);

  const courseName = location.state?.courseName || "Nome del Corso";
  const objectives = location.state?.objectives || ["Obiettivo 1", "Obiettivo 2"];

  useEffect(() => {
    const receivedKpis = location.state?.generatedKpis || [];
    const formattedKpis = receivedKpis.map((kpi) => ({
      name: kpi,
      value: 0
    }));
    setKpis(formattedKpis);
  }, [location.state?.generatedKpis]);

  useEffect(() => {
    if (id && !messages.length) {
      const fetchAssessment = async () => {
        try {
          const response = await fetch(`${apiUrl}/api/get-assessment/${id}`);
          if (response.ok) {
            const data = await response.json();
            setMessages(data.conversation || []);
          }
        } catch (error) {
          console.error('Errore durante la chiamata al backend:', error);
        }
      };
      fetchAssessment();
    }
  }, [id, messages]);

  useEffect(() => {
    if (achievementQueue.length > 0) {
      showNextAchievement();
    }
  }, [achievementQueue]);

  const handleAchievementUnlock = (kpi) => {
    console.log("Achievement sbloccato:", kpi.name);
    if (!unlockedAchievements.includes(kpi.name) && !achievementQueue.includes(kpi.name)) {
      setUnlockedAchievements(prevUnlocked => [...prevUnlocked, kpi.name]);
      setAchievementQueue(prevQueue => [...prevQueue, kpi.name]);
    }
  };

  const showNextAchievement = () => {
    if (achievementQueue.length > 0) {
      const nextAchievement = achievementQueue[0];

      // Mostra la notifica per l'achievement sbloccato
      notification.open({
        message: <b>'Achievement Sbloccato!'</b>,
        description: (
          <span>
            Congratulazioni! Hai sbloccato l'achievement: <b>{nextAchievement}</b>
          </span>
        ),
        icon: <TrophyOutlined style={{ color: '#108ee9' }} />,
        duration: 4.5  // Durata della notifica in secondi
      });

      // Rimuovi l'achievement dalla coda
      setAchievementQueue(prevQueue => prevQueue.slice(1));
    }
  };

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSendMessage = async () => {
    const trimmedInput = userInput.trim();
    if (trimmedInput) {
      const newMessage = { role: 'user', content: trimmedInput };
      const updatedMessages = [...messages, newMessage];
      setMessages(updatedMessages);
      setUserInput('');
  
      try {
        const response = await fetch(`${apiUrl}/api/create-bot`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ previous_conversation: updatedMessages }),
        });
  
        if (response.ok) {
          const result = await response.json();
          const botMessage = { role: 'assistant', content: result.bot_response };
          setMessages([...updatedMessages, botMessage]);
  
          const kpiResponse = await fetch(`${apiUrl}/api/update-kpi`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              input_hr: { nome_corso: courseName, obiettivi: objectives },
              conversation: [...updatedMessages, botMessage],
              kpi_list: kpis
            }),
          });
  
          if (kpiResponse.ok) {
            const kpiResult = await kpiResponse.json();
            console.log('Risposta API KPI:', kpiResult);
            const updatedKpis = kpiResult.scores.map((score, index) => ({
              ...kpis[index],
              value: kpis[index].value + score.value
            }));
            setKpis(updatedKpis);
            console.log('KPI aggiornati:', updatedKpis);

            kpiResult.scores.forEach((score, index) => {
              if (score.value >= 5) {
                handleAchievementUnlock(kpis[index]);
              }
            });
          }
        }
      } catch (error) {
        console.error('Errore durante la chiamata al backend:', error);
      }
    }
  };

  const handleSaveAssessment = async () => {
    const assessmentData = {
      id,
      conversation: messages,
      courseName: courseName,
      objectives: objectives,
    };

    try {
      const response = await fetch(`${apiUrl}/api/save-assessment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(assessmentData),
      });

      if (response.ok) {
        alert('Assessment salvato con successo!');
      } else {
        const errorData = await response.json();
        alert(`Errore: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Errore durante il salvataggio dell\'assessment:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <ChatContainer>
      <Header style={{ background: '#ffffff', textAlign: 'center' }}>
        <Title level={4}>Completa lo scenario</Title>
      </Header>
      <Content style={{ width: '100%', padding: '20px' }}>
        <Row gutter={16}>
          <Col span={6}>
            <Card title="Progresso Valutazione">
              <KpiProgress kpis={kpis} onAchievementUnlock={handleAchievementUnlock} />
            </Card>
          </Col>
          <Col span={18}>
            <ChatBox>
              {messages.map((message, index) => (
                message.content && (
                  <React.Fragment key={index}>
                    <ChatItem role={message.role}>
                      <Avatar
                        icon={message.role === 'user' ? <UserOutlined /> : <RobotOutlined />}
                        style={{ backgroundColor: message.role === 'user' ? '#fdbe02' : '#1890ff' }}
                      />
                      <ChatBubble role={message.role}>
                        <ReactMarkdown>{message.content}</ReactMarkdown>
                      </ChatBubble>
                    </ChatItem>
                    <Divider />
                  </React.Fragment>
                )
              ))}
              <div ref={chatEndRef} />
            </ChatBox>

            <div style={{ display: 'flex', gap: '10px' }}>
              <StyledInput
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onPressEnter={handleKeyPress}
                placeholder="Scrivi un messaggio..."
                autoSize={{ minRows: 1, maxRows: 5 }}
              />
              <StyledButton onClick={handleSendMessage}>Invia</StyledButton>
            </div>

            <SaveButton onClick={handleSaveAssessment}>Salva Assessment</SaveButton>
          </Col>
        </Row>
      </Content>
    </ChatContainer>
  );
}

export default Chat;