// src/components/Layout.js
import React from 'react';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';  // Outlet renderizza le pagine figlie
import styled from 'styled-components';

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  margin-left: 250px;  // Margine standard per desktop

  @media (max-width: 768px) {
    margin-left: 0;  // Rimuove il margine su mobile per far spazio alla sidebar collapsible
  }
`;

function Layout() {
  return (
    <div style={{ display: 'flex', minHeight: '100vh' }}>
      <Sidebar />

      <MainContent>
        <Outlet />  {/* Outlet renderizza la pagina corrente */}
      </MainContent>
    </div>
  );
}

export default Layout;
