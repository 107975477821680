import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Card, Row, Col, Typography, Switch, Form, Tooltip, Space, Upload, Tour } from 'antd';
import { PlusOutlined, MinusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import templates from './assessmentTemplates';
import styled from 'styled-components';
import apiUrl from '../config';

const { TextArea } = Input;
const { Title, Paragraph } = Typography;

const TemplateCard = styled(Card)`
  height: 100%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  }

  &.active {
    background-color: #007aff;
    color: white;

    .ant-card-head-title {
      color: White;
    }

    .ant-typography {
      color: white;
    }
  }
`;

function AssessmentForm() {
  const [activeTemplate, setActiveTemplate] = useState('');
  const [courseName, setCourseName] = useState('');
  const [assessmentObjectives, setAssessmentObjectives] = useState('');
  const [instructions, setInstructions] = useState([{ id: Date.now(), title: '', content: '', isRemovable: false }]);
  const [autoClose, setAutoClose] = useState(false);
  const [questionsCount, setQuestionsCount] = useState(1);
  const [files, setFiles] = useState([{ id: Date.now(), fileName: '', description: '', isRemovable: false }]);
  const [responseMessage, setResponseMessage] = useState('');
  const [tourOpen, setTourOpen] = useState(false);  // Aggiunta dello stato per il Tour

  const navigate = useNavigate();

  const handleInstructionChange = (id, field, value) => {
    const updatedInstructions = instructions.map((instruction) => {
      if (instruction.id === id) {
        return { ...instruction, [field]: value };
      }
      return instruction;
    });
    setInstructions(updatedInstructions);
  };

  const handleFileChange = (id, field, value) => {
    const updatedFiles = files.map((file) => {
      if (file.id === id) {
        return { ...file, [field]: value };
      }
      return file;
    });
    setFiles(updatedFiles);
  };

  const applyTemplate = (templateName) => {
    const template = templates[templateName];
    if (template) {
      setActiveTemplate(templateName);
      setCourseName(template.courseName || '');
      setAssessmentObjectives(template.objectives || '');
      setInstructions(template.instructions.map((instruction, index) => ({
        ...instruction,
        id: Date.now() + index, 
        isRemovable: index > 1 ? true : false,
      })));
      setFiles([{ id: Date.now(), fileName: '', description: '', isRemovable: true }]);
      setAutoClose(template.autoClose || false);
      setQuestionsCount(template.questionsCount || 1);
    }
  };

  const handleAddInstruction = () => {
    setInstructions([...instructions, { id: Date.now(), title: '', content: '', isRemovable: true }]);
  };

  const handleRemoveInstruction = (id) => {
    setInstructions(instructions.filter(instruction => instruction.id !== id));
  };

  const handleAddFile = () => {
    setFiles([...files, { id: Date.now(), fileName: '', description: '', isRemovable: true }]);
  };

  const handleRemoveFile = (id) => {
    setFiles(files.filter(file => file.id !== id));
  };

  const handleSubmit = async () => {
    const assessmentData = {
        courseName,
        objectives: assessmentObjectives.split('\n').map(obj => obj.trim()).filter(obj => obj),
        courseTopics: instructions.map(instruction => instruction.title).filter(title => title.trim()),
        customFields: instructions.map(instruction => ({
            title: instruction.title.trim(),
            content: instruction.content.trim(),
        })).filter(field => field.title || field.content),
        files: files.map(file => ({
            fileName: file.fileName.trim(),
            description: file.description.trim(),
        })).filter(file => file.fileName),
        autoClose,
        questionsCount,
    };

    try {
      // PRIMA: Genera KPI dall'AI #2
      const kpiResponse = await fetch(`${apiUrl}/api/generate-kpi`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              input_hr: {
                  nome_corso: courseName,
                  obiettivi: assessmentData.objectives,
                  argomenti: assessmentData.courseTopics,
              }
          }),
      });
  
      // Aggiungiamo un log per verificare la risposta del server
      console.log('Risposta KPI:', kpiResponse);
  
      if (kpiResponse.ok) {
          const kpiResult = await kpiResponse.json();
          console.log('KPI generati:', kpiResult);  // Verifica i KPI
  
          // Verifica se kpi_list è un array o una stringa
          let generatedKpis;
          if (Array.isArray(kpiResult.kpi_list)) {
              generatedKpis = kpiResult.kpi_list;
          } else if (typeof kpiResult.kpi_list === 'string') {
              generatedKpis = kpiResult.kpi_list.split(',').map(kpi => kpi.trim());
          } else {
              throw new Error('Formato di kpi_list non riconosciuto');
          }
  
          assessmentData.generatedKpis = generatedKpis;
  
          // SECONDO: Crea il bot (AI #1)
          const botResponse = await fetch(`${apiUrl}/api/create-bot`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(assessmentData),
          });
  
          if (botResponse.ok) {
              const result = await botResponse.json();
              console.log('Bot creato con successo:', result);  // Log per il risultato del bot
              console.log("KPI generati e passati alla chat:", generatedKpis);
              // Naviga alla chat con i KPI e il bot pronti
              navigate('/chat', {
                state: {
                  conversation: result.previous_conversation,
                  courseName: assessmentData.courseName,
                  objectives: assessmentData.objectives,
                  generatedKpis, // Passa i KPI generati
                },
              });
          } else {
              setResponseMessage('Errore nella generazione dello scenario.');
          }
      } else {
          setResponseMessage('Errore nella generazione dei KPI.');
      }
  } catch (error) {
      console.error('Errore durante la chiamata al backend:', error);
      setResponseMessage('C\'è stato un errore durante la creazione dell\'assessment.'+error);
  }
};

  // Definiamo i passaggi del Tool Tour
  const steps = [
    {
      title: 'Seleziona un template',
      description: 'Puoi scegliere un template dall\'elenco per iniziare a creare un assessment.',
      target: () => document.querySelector('.ant-card'),
      placement: 'bottom',  // Puoi modificare il posizionamento
    },
    {
      title: 'Nome del corso',
      description: 'Oppure puoi crearlo da 0, inserisci il nome del corso qui.',
      target: () => document.querySelector('input[placeholder="Es. Cybersecurity 101"]'),
      placement: 'right', // Modifica il posizionamento per farlo apparire accanto
    },
    {
      title: 'Obiettivi dell’Assessment',
      description: 'Specifica gli obiettivi del corso qui.',
      target: () => document.querySelector('textarea[placeholder="Inserisci gli obiettivi separati da una nuova riga"]'),
      placement: 'right',
    },
    {
      title: 'Aggiungi Istruzione',
      description: 'Aggiungi o modifica le istruzioni per il corso.',
      target: () => document.querySelector('button.ant-btn-dashed'),
      placement: 'right',
    },
    {
      title: 'Carica file',
      description: 'Puoi caricare dei file qui.',
      target: () => document.querySelector('.ant-upload .ant-btn'),  // Selettore più specifico
      placement: 'right',  // Modifica il posizionamento per migliorare la visibilità
    },
    {
      title: 'Crea Assessment',
      description: 'Quando hai finito, clicca qui per creare l\'assessment.',
      target: () => document.querySelector('button[type="submit"]'),
      placement: 'top',
    },
  ];

  return (
    <div style={{ maxWidth: 900, margin: '50px auto', padding: 30, backgroundColor: '#fff', borderRadius: 16, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)' }}>
      <Title level={3} style={{ textAlign: 'center', marginBottom: 30 }}>Crea un nuovo Assessment</Title>

      <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} /> {/* Aggiunta del Tool Tour */}
      <Button onClick={() => setTourOpen(true)} style={{ marginBottom: '16px' }}>
        Avvia il Tour
      </Button>

      <Row gutter={[24, 24]} style={{ marginBottom: 30 }}>
        {Object.keys(templates).map((templateName) => (
          <Col span={8} key={templateName}>
            <TemplateCard
              title={templates[templateName].courseName}
              bordered={false}
              onClick={() => applyTemplate(templateName)}
              hoverable
              className={activeTemplate === templateName ? 'active' : ''}
            >
              {templates[templateName].objectives.split('\n').map((objective, index) => (
                <Paragraph key={index} style={{ marginBottom: 12 }}>{objective}</Paragraph>
              ))}
            </TemplateCard>
          </Col>
        ))}
      </Row>

      <Form layout="vertical" onFinish={handleSubmit}>
        <Row gutter={24} align="top">
          <Col span={12}>
            <Form.Item style={{ marginBottom: 16 }}>
              <Title level={4} style={{ marginBottom: 8, fontSize: '18px' }}>Nome del Corso</Title>
              <Tooltip title="Inserisci il nome del corso">
                <Input value={courseName} onChange={(e) => setCourseName(e.target.value)} placeholder="Es. Cybersecurity 101" />
              </Tooltip>
            </Form.Item>

            <Form.Item style={{ marginBottom: 16 }}>
              <Title level={5} style={{ marginBottom: 8, fontSize: '16px' }}>Obiettivi dell’Assessment</Title>
              <Tooltip title="Specifica gli obiettivi dell'assessment">
                <TextArea value={assessmentObjectives} onChange={(e) => setAssessmentObjectives(e.target.value)} rows={4} placeholder="Inserisci gli obiettivi separati da una nuova riga" />
              </Tooltip>
            </Form.Item>

            <Form.List name="istruzioni">
              {(fields, { add, remove }) => (
                <>
                  {instructions.map((instruction, index) => (
                    <Space direction="vertical" style={{ display: 'flex', marginBottom: 16 }} key={instruction.id}>
                      <Form.Item style={{ marginBottom: 8 }}>
                        <Title level={5} style={{ marginBottom: 4, fontSize: '16px' }}>{`Titolo Istruzione ${index + 1}`}</Title>
                        <Tooltip title="Inserisci il titolo dell'istruzione">
                          <Input value={instruction.title} onChange={(e) => handleInstructionChange(instruction.id, 'title', e.target.value)} placeholder="Titolo dell'istruzione" />
                        </Tooltip>
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 8 }}>
                        <Title level={5} style={{ marginBottom: 4, fontSize: '14px' }}>{`Contenuto Istruzione ${index + 1}`}</Title>
                        <Tooltip title="Inserisci il contenuto dell'istruzione">
                          <TextArea value={instruction.content} onChange={(e) => handleInstructionChange(instruction.id, 'content', e.target.value)} rows={3} placeholder="Dettagli dell'istruzione" />
                        </Tooltip>
                      </Form.Item>
                      {instruction.isRemovable && (
                        <Button type="dashed" onClick={() => handleRemoveInstruction(instruction.id)} icon={<MinusCircleOutlined />}>
                          Rimuovi Istruzione
                        </Button>
                      )}
                    </Space>
                  ))}
                  <Form.Item style={{ marginBottom: 16 }}>
                    <Button type="dashed" onClick={handleAddInstruction} block icon={<PlusOutlined />}>
                      Aggiungi Istruzione
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>

          <Col span={12}>
            <div style={{ padding: '20px', background: '#f5f5f5', borderRadius: '8px', marginBottom: '20px', height: 'fit-content' }}>
              <Form.Item label="Chiusura Automatica (decisa dall'AI)" style={{ marginBottom: 16 }}>
                <Tooltip title="Abilita la chiusura automatica basata sull'AI.">
                  <Switch checked={autoClose} onChange={(checked) => setAutoClose(checked)} />
                </Tooltip>
              </Form.Item>

              {!autoClose && (
                <Form.Item label="Numero di Domande prima della Chiusura" tooltip="Specifica il numero di domande prima della chiusura automatica" style={{ marginBottom: 16 }}>
                  <Input type="number" value={questionsCount} onChange={(e) => setQuestionsCount(e.target.value)} min={1} />
                </Form.Item>
              )}
            </div>

            <Title level={4} style={{ marginBottom: 16, fontSize: '18px' }}>Allegati</Title>
            <Form.List name="files">
              {(fields, { add, remove }) => (
                <>
                  {files.map((file, index) => (
                    <Space direction="vertical" style={{ display: 'flex', marginBottom: 16 }} key={file.id}>
                      <Form.Item style={{ marginBottom: 8 }}>
                        <Title level={5} style={{ marginBottom: 4, fontSize: '16px' }}>{`Nome File ${index + 1}`}</Title>
                        <Input value={file.fileName} onChange={(e) => handleFileChange(file.id, 'fileName', e.target.value)} placeholder="Nome del file" />
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 8 }}>
                        <Title level={5} style={{ marginBottom: 4, fontSize: '14px' }}>{`Specifiche Optional ${index + 1}`}</Title>
                        <TextArea value={file.description} onChange={(e) => handleFileChange(file.id, 'description', e.target.value)} rows={2} placeholder="Specifiche opzionali" />
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 16 }}>
                        <Upload>
                          <Button icon={<UploadOutlined />}>Carica File</Button>
                        </Upload>
                      </Form.Item>
                      {file.isRemovable && (
                        <Button type="dashed" onClick={() => handleRemoveFile(file.id)} icon={<MinusCircleOutlined />}>
                          Rimuovi File
                        </Button>
                      )}
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={handleAddFile} block icon={<PlusOutlined />}>
                      Aggiungi File
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Crea Assessment
          </Button>
        </Form.Item>

        {responseMessage && <Typography.Text type="danger">{responseMessage}</Typography.Text>}
      </Form>
    </div>
  );
}

export default AssessmentForm;