import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card, Progress, Typography } from 'antd';
import styled from 'styled-components';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const UserDetailContainer = styled.div`
  padding: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const CardStyled = styled(Card)`
  margin-top: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Sidebar = styled.div`
  flex: 4;
`;

function UserDetail() {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const savedUserFeedback = JSON.parse(localStorage.getItem(`userFeedback_${userId}`));
    if (savedUserFeedback && savedUserFeedback.length > 0) {
      setUserData(savedUserFeedback[userId - 1]);
    }
  }, [userId]);

  if (!userData) {
    return <Text>Caricamento...</Text>;
  }

  const ratingPercentage = (userData.rating / 5) * 100;

  return (
    <UserDetailContainer>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <Title level={2}>
            Dettagli di {userData.name} - {Math.round(ratingPercentage)}%
          </Title>
          <CardStyled title="Argomenti Mastered">
            <ul>
              <li>D.Lgs 475/92 – DPI</li>
              <li>D.M. 388/2003 – primo soccorso aziendale</li>
              <li>D.M. 01/09/2021 – manutenzione antincendio</li>
            </ul>
          </CardStyled>

          <CardStyled title="Argomenti da rivedere">
            <ul>
              <li>D.Lgs 151/2001 – rischi in gravidanza e allattamento</li>
              <li>D.P.R. 462/2001 – denuncia di installazioni e dispositivi di protezione</li>
            </ul>
          </CardStyled>

          <CardStyled title="Argomenti critici">
            <ul>
              <li>D.Lgs 152/2006 – ambiente</li>
              <li>Regolamento UE 2023/1230 – macchine</li>
            </ul>
          </CardStyled>
        </Col>

        <Col span={8}>
          <Sidebar>
            <CardStyled>
              <Title level={4}>Valutazione Complessiva</Title>
              <Progress
                type="circle"
                percent={Math.round(ratingPercentage)}
                width={120}
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
              />
            </CardStyled>

            <CardStyled title="Altri Dettagli">
              <Text>Performance Iniziale: {userData.initialPerformance}%</Text>
              <br />
              <Text>Performance Finale: {userData.finalPerformance}%</Text>
              <br />
              <Text
                strong
                style={{
                  color: userData.improvement >= 0 ? '#3f8600' : '#cf1322',
                }}
              >
                Miglioramento:{' '}
                {userData.improvement >= 0 ? (
                  <>
                    <ArrowUpOutlined /> +{userData.improvement}%
                  </>
                ) : (
                  <>
                    <ArrowDownOutlined /> {userData.improvement}%
                  </>
                )}
              </Text>
            </CardStyled>
          </Sidebar>
        </Col>
      </Row>
    </UserDetailContainer>
  );
}

export default UserDetail;
