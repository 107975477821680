import React, { useEffect } from 'react';
import { Progress } from 'antd'; // Importiamo il componente Progress da Ant Design

function KpiProgress({ kpis, onAchievementUnlock }) {
  // Monitoriamo ogni KPI per vedere quando la progress bar raggiunge il 100%
  useEffect(() => {
    kpis.forEach(kpi => {
      if (kpi.value >= 5) { // Verifichiamo se il valore è completo (ipotizzando che 5 sia il massimo)
        onAchievementUnlock(kpi); // Invochiamo la funzione per sbloccare l'achievement
      }
    });
  }, [kpis, onAchievementUnlock]);

  return (
    <div>
      {kpis.map((kpi, index) => (
        <div key={index} style={{ marginBottom: '15px' }}>
          <h4 style={{ fontSize: '14px', fontWeight: 'normal', marginBottom: '8px' }}>{kpi.name}</h4> {/* Testo più piccolo e non in grassetto */}
          <Progress
            percent={(kpi.value / 5) * 100} // Riempimento della progress bar
            strokeWidth={8} // Imposta lo spessore della barra di progresso
            showInfo={false} // Rimuovi il testo con la percentuale
            strokeColor="#007aff" // Colore della barra di progresso
          />
        </div>
      ))}
    </div>
  );
}

export default KpiProgress;