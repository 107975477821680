import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography, Tooltip } from '@mui/material';
import StatisticCard from '../components/StatisticCard';
import CourseCard from '../components/CourseCard';
import PerformanceList from '../components/PerformanceList';
import UtilizationChart from '../components/UtilizationChart';
import ImprovementChart from '../components/ImprovementChart';
import { FaClipboardList, FaStar } from 'react-icons/fa';
import styled from 'styled-components';
import InfoIcon from '@mui/icons-material/Info';
import apiUrl from '../config';

const DashboardContainer = styled(Container)`
  margin-top: 20px;
  margin-left: 250px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 10px;
  }
`;

const GridContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 10px;
  }
`;

const LeftColumn = styled(Grid)`
  flex: 2;
`;

const RightColumn = styled(Grid)`
  flex: 1;
  margin-left: 20px;
`;

const HorizontalScrollContainer = styled.div`
  display: flex;
  flex-wrap: nowrap; /* Non permettere che le card vadano su più righe */
  gap: 16px;
  overflow-x: auto; /* Abilita lo scroll orizzontale */
  padding-bottom: 10px; /* Spazio per la scrollbar */
  scrollbar-width: thin; /* Per Firefox */
  
  &::-webkit-scrollbar {
    height: 8px; /* Altezza della scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Colore della scrollbar */
    border-radius: 10px; /* Bordo arrotondato */
  }

  & > * {
    flex: 0 1 calc(33.3333% - 16px); /* Tre card visibili per riga */
    margin-right: 16px; /* Spaziatura tra le card */
  }

  @media (max-width: 768px) {
    gap: 8px;
  }
`;


const CustomCard = styled(StatisticCard)`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    background-color: #f0f4ff; /* Colore di sfondo leggero al passaggio del mouse */
  }

  .icon {
    font-size: 48px; /* Ingrandisci le icone */
    color: #007aff; /* Colore primario per le icone */
  }

  .number {
    font-size: 32px; /* Aumenta la dimensione del numero */
    font-weight: bold;
    margin-top: 10px;
    color: #333; /* Colore del testo */
  }

  .label {
    font-size: 18px; /* Aumenta la dimensione del testo */
    margin-top: 5px;
    color: #555; /* Colore leggermente più scuro per la descrizione */
  }
`;

function Dashboard() {
  const [assessments, setAssessments] = useState([]);
  const [performanceData, setPerformanceData] = useState({});

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/get-assessments`);
        if (response.ok) {
          const data = await response.json();
          setAssessments(data);

          const savedPerformanceData = JSON.parse(localStorage.getItem('performanceData') || '{}');
          const updatedPerformanceData = { ...savedPerformanceData };

          data.forEach((assessment) => {
            if (!updatedPerformanceData[assessment.id]) {
              const participants = Math.floor(Math.random() * 48) + 50;
              const rating = Math.floor(Math.random() * 48) + 50;
              const averageImprovement = Math.round(Math.random() * 20) + 10;

              updatedPerformanceData[assessment.id] = {
                participants,
                rating,
                averageImprovement,
              };
            }
          });

          setPerformanceData(updatedPerformanceData);
          localStorage.setItem('performanceData', JSON.stringify(updatedPerformanceData));
          
        } else {
          console.error('Errore nel recupero degli assessment salvati.');
        }
      } catch (error) {
        console.error('Errore durante la chiamata al backend:', error);
      }
    };

    fetchAssessments();
  }, []);

  const bestPerformanceData = assessments
    .filter(assessment => performanceData[assessment.id]?.rating > 50)
    .sort((a, b) => performanceData[b.id]?.rating - performanceData[a.id]?.rating)
    .slice(0, 3)
    .map(assessment => ({
      id: assessment.id,
      course: assessment.courseName,
      ...performanceData[assessment.id],
    }));

  const worstPerformanceData = assessments
    .filter(assessment => performanceData[assessment.id]?.rating <= 50)
    .sort((a, b) => performanceData[a.id]?.rating - performanceData[b.id]?.rating)
    .slice(0, 3)
    .map(assessment => ({
      id: assessment.id,
      course: assessment.courseName,
      ...performanceData[assessment.id],
    }));

  return (
    <DashboardContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <CustomCard icon={<FaClipboardList />} number="3.460" label="Assessment completati" />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomCard icon={<FaStar />} number="76%" label="Valutazione media" />
        </Grid>
      </Grid>
      <GridContainer container spacing={3}>
        <LeftColumn item xs={12} md={8}>
          <Typography variant="h5" gutterBottom>I miei corsi</Typography>
          <HorizontalScrollContainer>
            {assessments.map((assessment) => (
              <Grid item xs={12} sm={6} md={4} key={assessment.id}>
                <CourseCard 
                  title={assessment.courseName} 
                  participants={performanceData[assessment.id]?.participants || 0} 
                  rating={performanceData[assessment.id]?.rating || 0} 
                  link={`/Assessment/${assessment.id}`} 
                />
              </Grid>
            ))}
              </HorizontalScrollContainer>
          <Grid container spacing={3} style={{ marginTop: '20px' }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Tempo di utilizzo</Typography>
              <UtilizationChart />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Miglioramento medio degli studenti</Typography>
              <ImprovementChart value={Math.round(Object.values(performanceData).reduce((sum, data) => sum + data.averageImprovement, 0) / Object.values(performanceData).length || 0)} />
            </Grid>
          </Grid>
        </LeftColumn>
        <RightColumn item xs={12} md={4}>
          <PerformanceList title="Best performance" data={bestPerformanceData} good />
          <PerformanceList title="Worst performance" data={worstPerformanceData} />
        </RightColumn>
      </GridContainer>
    </DashboardContainer>
  );
}

export default Dashboard;
