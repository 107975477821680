import React, { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Container, Typography } from '@mui/material';
import styled from 'styled-components';

const FormContainer = styled(Container)`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
`;

function ReportOptionsForm({ onGenerateReport, assessment }) {
  const [sentimentAnalysis, setSentimentAnalysis] = useState(false);
  const [specificContentEvaluation, setSpecificContentEvaluation] = useState(false);
  const [customField, setCustomField] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const options = {
      sentimentAnalysisRequested: sentimentAnalysis,
      specificContentEvaluationRequested: specificContentEvaluation,
      customField: customField.trim(),
      courseName: assessment.courseName,   // Aggiungi il nome del corso dall'assessment
      objectives: assessment.objectives,   // Aggiungi gli obiettivi dall'assessment
      conversation: assessment.conversation,  // Aggiungi le conversazioni dall'assessment
      customFields: assessment.customFields || []  // Campi extra personalizzati se esistono
    };

    onGenerateReport(options);
};


  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <Typography variant="h5" gutterBottom>
          Opzioni Report di Valutazione
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={sentimentAnalysis}
              onChange={(e) => setSentimentAnalysis(e.target.checked)}
            />
          }
          label="Includi Analisi del Sentiment"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={specificContentEvaluation}
              onChange={(e) => setSpecificContentEvaluation(e.target.checked)}
            />
          }
          label="Valuta Contenuti Specifici"
        />
        <TextField
          label="Campo Personalizzato (opzionale)"
          value={customField}
          onChange={(e) => setCustomField(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" type="submit" fullWidth>
          Genera Report
        </Button>
      </form>
    </FormContainer>
  );
}

export default ReportOptionsForm;
