import React, { useState, useEffect } from 'react';
import { Typography, Button, Row, Col, Card, Modal, Skeleton, Input, Select } from 'antd'; // Importa i componenti di Ant Design
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ReportOptionsForm from './ReportOptionsForm'; // Assicurati di usare il percorso corretto
import { FileOutlined, DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons'; // Importa icone
import apiUrl from '../config';

const { Meta } = Card;
const { Search } = Input;
const { Option } = Select;

const AssessmentContainer = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Assicura che tutte le card abbiano la stessa altezza */
`;

const StyledButton = styled(Button)`
  background-color: #1677FF; /* Colore verde */
  color: white;
  border-radius: 8px; /* Bordi arrotondati */
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3F96FE; /* Effetto hover verde scuro */
  }

  &:focus {
    outline: none; /* Rimuovi il contorno */
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
`;

// Funzione per troncare il testo degli obiettivi
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

function Assessment() {
  const [assessments, setAssessments] = useState([]);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/get-assessments`);
        if (response.ok) {
          const data = await response.json();
          setAssessments(Array.isArray(data) ? data : []);
        } else {
          console.error('Errore nel recupero degli assessment salvati.');
        }
      } catch (error) {
        console.error('Errore durante la chiamata al backend:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessments();
  }, []);

  const handleOpenReportDialog = (assessmentId) => {
    setSelectedAssessmentId(assessmentId);
    setReportDialogOpen(true);
  };

  const handleCloseReportDialog = () => {
    setReportDialogOpen(false);
  };

  const handleGenerateReport = async (options) => {
    try {
      const response = await fetch(`${apiUrl}/api/generate-report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(options),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.docx'; // Scarica come file .docx
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        console.error('Errore nella generazione del report.');
      }
    } catch (error) {
      console.error('Errore durante la chiamata al backend:', error);
    }
  };

  const handleCreateNew = () => {
    navigate('/create-assessment');
  };

  const filteredAssessments = assessments.filter((assessment) =>
    assessment.courseName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <AssessmentContainer>
      <Typography.Title level={2} style={{ marginBottom: '20px' }}>
        Gestione Assessment
      </Typography.Title>

      <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={handleCreateNew}>
          Crea Nuovo Assessment
        </Button>
        <Search
          placeholder="Cerca assessment"
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 300 }}
        />
        <Select defaultValue="ordina" style={{ width: 150 }}>
          <Option value="data">Per Data</Option>
          <Option value="nome">Per Nome</Option>
        </Select>
      </Row>

      <Typography.Title level={4} style={{ marginBottom: '20px' }}>
        Assessment Salvati
      </Typography.Title>

      {loading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[16, 16]}>
          {filteredAssessments.map((assessment, index) => (
            <Col xs={24} sm={12} md={8} lg={6} key={index}>
              <StyledCard
                hoverable
                title={assessment.courseName}
                extra={<Link to={`/chat/${assessment.id}`}>Vai alla Chat</Link>}
              >
                <Meta
                  description={`Obiettivi: ${truncateText(assessment.objectives.join(', '), 100)}`} // Limitiamo a 100 caratteri
                />
                <StyledButton
                  icon={<DownloadOutlined />}
                  onClick={() => handleOpenReportDialog(assessment.id)}
                  style={{ marginTop: '10px' }}
                >
                  Scarica Report
                </StyledButton>
              </StyledCard>
            </Col>
          ))}
        </Row>
      )}

      {/* Modal per la generazione del report */}
      <Modal
        title="Genera Report di Valutazione"
        visible={reportDialogOpen}
        onCancel={handleCloseReportDialog}
        footer={null}
      >
        <ReportOptionsForm
          onGenerateReport={handleGenerateReport}
          assessment={assessments.find((a) => a.id === selectedAssessmentId)} // Passa l'assessment corretto
        />
      </Modal>
    </AssessmentContainer>
  );
}

export default Assessment;
